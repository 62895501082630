const sendData = (value, setStatus, setWaiting) => {
  setWaiting(true)
  console.log(value)
  if (value.name === "" || value.email === "" || value.email === "") {
    setStatus("error")
    setWaiting(false)
    return null
  }

  var data = {
    name: value.name,
    email: value.email,
    message: value.message,
  }

  var xhr = new XMLHttpRequest()
  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === this.DONE) {
      const response = JSON.parse(this.response)
      if (response.errors) {
        setStatus("error")
        setWaiting(false)
      } else {
        setStatus("success")
        setWaiting(false)
      }
    }
  })

  xhr.open("POST", "https://mail.updatehub.io/contact")

  xhr.setRequestHeader("accept", "application/json")
  xhr.setRequestHeader("content-type", "application/json")

  xhr.send(JSON.stringify(data))
}

export default sendData
