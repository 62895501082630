import React from "react"
import styles from "./Buttons.module.scss"

const SendButton = ({ text, send, link, disabled }) =>
    <a
        className={`${styles.button} ${styles.buttonNav} ${disabled ? null : styles.disabled}`}
        href={link}
        onClick={disabled ? send : null}
    >
        {text}
    </a>

export default SendButton
