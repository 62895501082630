import React, { useState } from "react"
import style from "./Form.module.scss"

export default ({ name, setName, email, setEmail, message, setMessage }) => {
  const [inputName, setNameStyle] = useState(style.input)
  const [inputEmail, setEmailStyle] = useState(style.input)

  const validate = (value, setStyle) => {
    value === "" ? setStyle(style.inputError) : setStyle(style.input)
  }

  return (
    <form className={style.form}>
      <div className={inputName}>
        <div className={style.label}>Name</div>
        <input
          className={style.text}
          type="text"
          value={name}
          onBlur={() => validate(name, setNameStyle)}
          onClick={() => setName("")}
          onChange={event => setName(event.target.value)}
        />
      </div>
      <div className={inputEmail}>
        <div className={style.label}>Email</div>
        <input
          className={style.text}
          type="email"
          value={email}
          onBlur={() => validate(email, setEmailStyle)}
          onClick={() => setEmail("")}
          onChange={event => setEmail(event.target.value)}
        />
      </div>
      <div className={style.textarea}>
        <div className={style.label}>Message</div>
        <textarea
          className={style.text}
          rows="11"
          cols="30"
          type="text"
          value={message}
          onClick={() => setMessage("")}
          onChange={event => setMessage(event.target.value)}
        />
      </div>
    </form>
  )
}
