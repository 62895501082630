import React from "react"
import Layout from "../components/Layout.js"
import Hero from "../components/contact/Hero.js"
import Footer from "../components/contact/Footer"

export default () => (
  <Layout>
    <Hero />
    <Footer />
  </Layout>
)
