import React, { useState } from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import sendData from "./apiMail"
import HeroDetails from "./HeroDetails"
import Waiting from "./HeroDetails/Waiting"

export default () => {
  const [status, setStatus] = useState("form")
  const [waiting, setWaiting] = useState(false)
  const [name, setName] = useState("Name")
  const [email, setEmail] = useState("Email")
  const [message, setMessage] = useState("Message")
  const props = { name, setName, email, setEmail, message, setMessage }

  const send = () => {
    const value = { name: name, email: email, message: message }
    sendData(value, setStatus, setWaiting)
  }

  return (
    <section className={style.contactSection}>
      <div className={style.contactHeaderImg}></div>
      <div className={style.container}>
        <Row center="xs">
          <Col xs={12} sm={10} md={8}>
            <Waiting waiting={waiting} />
            <HeroDetails status={status} send={send} props={props} />
          </Col>
        </Row>
      </div>
    </section>
  )
}
