import React from "react"
import { Col } from "react-flexbox-grid"

const Success = ({ style, children }) => (
  <Col xs={12} className={style}>
    {children}
  </Col>
)

export default Success
