import React from "react"
import FormDetails from "./FormDetails"
import Success from "./Success"
import Error from "./Error"

const HeroDetails = ({ status, send, props }) => {
  switch (status) {
    case "form":
      return <FormDetails send={send} props={props} />
    case "success":
      return <Success email={props.email} />
    default:
      return <Error />
  }
}

export default HeroDetails
