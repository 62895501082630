import React from "react"
import style from "./Contact.module.scss"
import OutlineButton from "../../Buttons/OutlineButton"
import Contact from "./Contact"

const Error = () => (
  <Contact style={style.error}>
    <div className={style.iconError}>
      <img src="/imgs/errorIcon.svg" alt="error" />
    </div>
    <h1>Something went wrong</h1>
    <h2>There has been an error while sending your message.</h2>
    <div className={style.buttonError}>
      <OutlineButton text="try again" link="/contact/" />
    </div>
  </Contact>
)

export default Error
