import React, { useState } from "react"
import style from "./../Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import Form from "../Form"
import SendButton from "../../Buttons/SendButton"
import ReCAPTCHA from "react-google-recaptcha"
import Contact from "./Contact"

const FormDetails = ({ send, props }) => {
  const [status, setStatus] = useState(false)
  function onChange(value) {
    value ? setStatus(true) : setStatus(false)
  }

  return (
    <Contact style={style.contactHeader}>
      <h1>Get in touch</h1>
      <h2>You can use the form below to contact us.</h2>
      <Col>
        <Form {...props} />
      </Col>
      <Row between="lg" middle="lg" className={style.captchaBox}>
        <Col md={12} lg={6} className="center-md">
          <ReCAPTCHA
            className={style.captcha}
            sitekey="6LfhirQUAAAAACl2cue0b4OdAyi_Qh5ub3qE__kd"
            onChange={onChange}
          />
        </Col>
        <Col md={12} lg={6} className="end-lg">
          <SendButton text={"Send"} send={send} link={null} disabled={status} />
        </Col>
      </Row>
    </Contact>
  )
}

export default FormDetails
