import React from "react"
import style from "./Contact.module.scss"
import Contact from "./Contact"

const Success = ({ email }) => (
  <Contact style={style.success}>
    <div className={style.iconSuccess}>
      <img src="/imgs/successIcon.svg" alt="error" />
    </div>
    <h1>Thank you for contact us</h1>
    <p>We receive your message and will contact with ({email}).</p>
    <p>
      Read more about UpdatHub <a href="/features/#features">Features</a> or{" "}
      <a href="https://blog.updatehub.io">Blog</a>.
    </p>
  </Contact>
)

export default Success
