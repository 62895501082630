import React from "react"
import style from "./Waiting.module.scss"
import LoadingIcon from "./LoadingIcon"

const Waiting = ({ waiting }) => {
  return waiting ? (
    <div className={style.contactWaiting}>
      <div className={style.oval}>
        <div>
          <LoadingIcon />
          Please, wait a second, we are processing your message.
        </div>
      </div>
    </div>
  ) : null
}

export default Waiting
